import React from "react";
import { Col } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import box_img from "../../Asstes/Images/box_img.png";

const CategoryList = ({ value }) => {
  const navigate = useNavigate();

  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Col lg={4} md={6}>
      <div
        className="categorylist tile_box"
        onClick={() => {
          localStorage.setItem("listingType", value.listing_type);
          localStorage.setItem("listingType_name", value.title);
          navigate("/category", { state: value.listing_type });
          handleScroll();
        }}
      >
        <div
          className="img"
          style={{
            backgroundImage: `url(${value.image})`,
          }}
        ></div>
        <div className="title">
          <h2>{value.title}</h2>
        </div>
        <div className="link_btn icon">
          <a hrf="#">
            {value?.icon}
            {/* <FaChevronRight /> */}
          </a>
        </div>
      </div>
    </Col>
  );
};

export default CategoryList;
