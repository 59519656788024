import React, { useEffect, useState, CSSProperties } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import CategoryBox from "../Components/CategoryBox/CategoryBox";
import {
  GetCategory,
  ResetCategory,
  ResetCategoryFail,
} from "../Redux/Actions/Category";
import { useLocation } from "react-router-dom";
// import Loader from "react-spinners/ClipLoader";
import { BounceLoader, FadeLoader } from "react-spinners";

import { Spinner } from "react-bootstrap";

export const Category = () => {
  const location = useLocation();
  const listing_type = localStorage.getItem("listingType");

  const cat = [
    { id: 1, icon: "vbv", title: "Category Type" },
    { id: 2, icon: "vbv", title: "Category Type" },
    { id: 3, icon: "vbv", title: "Category Type" },
    { id: 4, icon: "vbv", title: "Category Type" },
    { id: 5, icon: "vbv", title: "Category Type" },
  ];

  // const override: CSSProperties = {
  //   display: "block",
  //   margin: "0 auto",
  //   // borderColor: "red",
  // };
  const dispatch = useDispatch();

  const servicePerPage = 6;
  const [next, setNext] = useState(servicePerPage);
  const [categoryData, setCategoryData] = useState([]);
  const [data, setData] = useState("");
  const [hotelCode, setHotelCode] = useState("");
  const [loader, setLoader] = useState(true);
  let [color, setColor] = useState("#36d7b7");

  const hotelData = useSelector((state) => {
    return state.hotelData.data;
  });
  const CategorySuccess = useSelector((state) => {
    return state.Category.category;
  });
  const CategoryFail = useSelector((state) => state?.Categories?.error);

  useEffect(() => {
    setLoader(true);
    // setFeatureLoader(true);

    const data = {
      listingType: listing_type,
      area: hotelData?.data?.data?.area,
      suburb: hotelData?.data?.data?.suburb,
    };
    dispatch(GetCategory(data));
    return () => {
      dispatch(ResetCategory());
      dispatch(ResetCategoryFail());
    };
  }, [listing_type]);

  useEffect(() => {
    if (CategorySuccess?.data?.code == 200) {
      setLoader(false);

      if (CategorySuccess?.data?.data?.length > 0) {
        setCategoryData(CategorySuccess?.data?.data);
      } else {
      }
    } else if (CategoryFail) {
      setLoader(false);
    }

    return () => {};
  }, [CategorySuccess, CategoryFail]);

  // const handleShowMorePosts = () => {
  //   setNext(next + servicePerPage);
  // };

  const all_data = {
    id: "All",
    name: "All",
  };
  return (
    <div className="category_pg">
      <Container>
        <Row>
          <Col md={12}>
            <div className="heading">
              <h1>{localStorage.getItem("listingType_name")}</h1>
            </div>
          </Col>
        </Row>
        <Row>
          {loader ? (
            <>
              <Spinner
                animation="border"
                variant="dark"
                style={{
                  display: "block",
                  margin: "0 auto",
                  height: "56px",
                  width: "10px",
                }}
              />
            </>
          ) : categoryData.length > 0 ? (
            <>
              {location.state == 5 || location.state == 6 ? (
                <>
                  <CategoryBox data={all_data} listingType={location.state} />
                </>
              ) : null}

              {/* {categoryData.slice(0, next).map((val, index) => ( */}
              {categoryData.map((val, index) => (
                <CategoryBox
                  data={val}
                  key={index}
                  listingType={location.state}
                />
              ))}
            </>
          ) : (
            <>
              <h2>No Categories</h2>
            </>
          )}

          {/* {next < categoryData?.length && (
            <>
              {" "}
              <div className="btn_div">
                <Button
                  className="black_btn"
                  style={{
                    backgroundColor: "#bae2aa",
                    borderColor: "#ffff",
                    width: "150px",
                    height: "50px",
                    color: "black",
                  }}
                  onClick={handleShowMorePosts}
                >
                  Load More
                </Button>
              </div>
            </>
          )} */}
        </Row>
      </Container>
    </div>
  );
};
