import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import clublocal from "../Asstes/Images/club_local.png";
import club50 from "../Asstes/Images/club50.png";
import lookaustralia from "../Asstes/Images/look_australia.png";
import myapartment from "../Asstes/Images/my_apartment.png";
import teamjob from "../Asstes/Images/team_job.png";
import tbnbusiness from "../Asstes/Images/tbn_business.png";
import yourhotel from "../Asstes/Images/your_hotel.png";
import yourhostel from "../Asstes/Images/your_hostel.png";

const TeamAustralia = () => {
  const teamAus = [
    {
      id: 1,
      name: "Hotels, resorts, motels, and other accommodation providers",
    },
    { id: 2, name: "Restaurants, bars, and other eateries" },
    { id: 3, name: "Beauty and wellbeing businesses" },
    { id: 4, name: "Retail" },
    { id: 5, name: "Personal Services" },
    { id: 7, name: "Trades & Services" },
    { id: 8, name: "Anything Local" },
    { id: 9, name: "& Much More" },
  ];

  const channelLogo = [
    { id: 1, image: clublocal },
    { id: 2, image: club50 },
    { id: 3, image: lookaustralia },
    { id: 4, image: myapartment },
    { id: 5, image: teamjob },
    { id: 6, image: tbnbusiness },
    { id: 7, image: yourhotel },
    { id: 8, image: yourhostel },
  ];
  return (
    <div className="team_australia_main">
      <Container>
        <Row>
          <Col md={8} className="offset-md-2">
            <h2>Team Australia</h2>
            <p>
              TEAM Australia has developed several mobile and web applications
              that help small businesses to market and promote their products
              and services to specific audiences. Our ecosystem of partner
              businesses include:
            </p>
            <ul>
              {teamAus?.map((list) => (
                <li>{list?.name}</li>
              ))}
            </ul>
            <p>
              TEAM helps Businesses Reach More of the People they Want to Reach.
            </p>
            <p>We deliver Relevance & Value to our Users.</p>
            <p>Our marketing channels:</p>
            <div className="marketing_channel_main">
              {channelLogo?.map((logo) => (
                <div className="marketing_channel_logo">
                  <img src={logo?.image} alt="" />
                </div>
              ))}
            </div>
            <p>
              For more information, please send us an email:{" "}
              <a href="mailto:info@teamts.com.au">info@teamts.com.au</a> or give
              us a call{" "}
              <a className="bottom_contact_info" href="tel:07 5635 4369">
                07 5635 4369
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TeamAustralia;
