import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const Service = (data) => {
  const hoteldata = JSON.parse(localStorage.getItem("HotelData"));
  const serviceId = localStorage.getItem("serviceId");

  let url = "";

  if (data.catId == "All") {
    url = `${apiActiveURL}/all_services?subrub=${hoteldata[1]}&listing_type=${data.listingType}`;
  } else {
    url = `${apiActiveURL}/services/${serviceId}?subrub=${hoteldata[1]}&listing_type=${data.listingType}`;
  }

  var config = {
    method: "GET",
    headers: {
      AppKey: appKey,

      AppId: appId,
    },
    url,
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        if (res.data.status) {
          ServiceSuccess(dispatch, res);
        } else {
          ServiceFail(dispatch, res.data.message);
        }
      })
      .catch((e) => ServiceFail(dispatch, e.message));
  };
};

export const ResetService = () => {
  return (dispatch) => {
    dispatch({ type: "SERVICE_SUCCESS", payload: null });
  };
};

const ServiceSuccess = (dispatch, res) => {
  dispatch({ type: "SERVICE_SUCCESS", payload: res });
};

const ServiceFail = (dispatch, res) => {
  dispatch({ type: "SERVICE_FAIL", payload: res });
};
export const ResetServiceFail = () => {
  return (dispatch) => {
    dispatch({ type: "SERVICE_FAIL", payload: null });
  };
};
