import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row } from "react-bootstrap";
import CategoryList from "../Components/CategoryList/CategoryList";
import { GetHotel } from "../Redux/Actions";
import { useParams } from "react-router-dom";
import local_home from "../../src/Asstes/Images/LocalRegion_home.png";
import whatson_home from "../../src/Asstes/Images/WhatsOn_home.png";
import tour_home from "../../src/Asstes/Images/Tour_home.png";
import restaurant_home from "../../src/Asstes/Images/Restaurant_home.png";
import beauty_home from "../../src/Asstes/Images/Beauty_home.png";
import deals_home from "../../src/Asstes/Images/Deals&Offers.png";
import { MdHistory } from "react-icons/md";
import { FaPlaceOfWorship } from "react-icons/fa";
import { GiTowerBridge, GiSaloon } from "react-icons/gi";
import { IoRestaurant } from "react-icons/io5";
import { MdOutlineLocalOffer } from "react-icons/md";

export const Home = () => {
  const myparams = useParams();

  const dispatch = useDispatch();

  const hotelErr = useSelector((state) => state.hotelData.error);

  useEffect(() => {
    let data = myparams.id;

    let urlKey = data.substring(1, data.length - 1);
    console.log(urlKey, "idd----");
    localStorage.setItem("urlKey", urlKey);

    let dataKey = {
      key: urlKey ? urlKey : localStorage.getItem("urlKey"),
    };

    dispatch(GetHotel(dataKey));
  }, []);

  const catListing = [
    {
      listing_type: 16,
      image: local_home,
      title: "The Local Region & Our History",
      icon: <MdHistory size={22} />,
    },
    {
      listing_type: 8,
      image: whatson_home,
      title: "What's On & Things to Do",
      icon: <FaPlaceOfWorship size={22} />,
    },
    {
      listing_type: 6,
      image: tour_home,
      title: "Tours & Attractions",
      icon: <GiTowerBridge size={22} />,
    },
    {
      listing_type: 5,
      image: restaurant_home,
      title: "Restaurants, Bars & Eateries",
      icon: <IoRestaurant size={22} />,
    },
    {
      listing_type: 9,
      image: beauty_home,
      title: "Beauty & Retail",
      icon: <GiSaloon size={22} />,
    },
    {
      listing_type: 7,
      image: deals_home,
      title: "Special Offers & Promotions",
      icon: <MdOutlineLocalOffer size={22} />,
    },
  ];
  return (
    <div className="home_pg">
      {hotelErr == null ? (
        <>
          <Container>
            <Row>
              {catListing.map((val, index) => (
                <CategoryList key={index} value={val} />
              ))}
            </Row>
          </Container>
        </>
      ) : (
        <>
          <h1>404 Not Found</h1>
        </>
      )}
    </div>
  );
};
