import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import app_icon from "../../Asstes/Images/app-store.png";
import play_icon from "../../Asstes/Images/google_play.png";
import { useSelector } from "react-redux";

const DownloadLinkStore = () => {
  return (
    <div className="DownloadLink">
      <Container>
        <Row>
          <Col md={10} className="offset-md-1">
            <div className="Download_box">
              {/* <h2>Download the full Look App</h2> */}
              <h2>Coming soon - the full Look-in-Australia mobile App</h2>
              <div className="group_img">
                <a>
                  <img src={app_icon} />
                </a>
                <a>
                  <img src={play_icon} />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default DownloadLinkStore;
