import React, { useState, useEffect, useRef, useCallback } from "react";
import { Container, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import BackArrow from "../Asstes/Images/arrow_back.png";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Search } from "../Redux/Actions";
import { RequestSearch } from "../Redux/Actions";
import { RiMenu3Line } from "react-icons/ri";
import { RxCross1 } from "react-icons/rx";
import _ from "lodash";

import { IoIosSearch } from "react-icons/io";
import { apiActiveURL, appId, appKey } from "../ApiBaseURL";
import axios from "axios";
const Header = ({ Inputref, mobileMenu, setmobileMenu }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listing_type = localStorage.getItem("listingType");
  const hoteldata = JSON.parse(localStorage.getItem("HotelData"));
  const [data, setData] = useState("");
  const [searchString, setSearchString] = useState("");
  const [loader, setLoader] = useState(false);
  const [searchData, setSearchData] = useState("");
  // Search Bar Hooks
  const [searchResults, setSearchResults] = useState([]);
  const [search, onChangeSearch] = useState("");
  const menuRef = useRef();

  const delayedQuery = useCallback(
    _.debounce((listingType, area, suburb, categoryId, serviceId, query) => {
      console.log(
        listingType,
        area,
        suburb,
        categoryId,
        serviceId,
        query,
        "listingType, area, suburb, categoryId, serviceId, query"
      );
      sendQuery(listingType, area, suburb, categoryId, serviceId, query);
    }, 300),
    []
  );

  const handleMobileMenu = () => {
    setmobileMenu(!mobileMenu);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setmobileMenu(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.addEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const hotelData = useSelector((state) => {
    return state.hotelData.data;
  });
  const hotelErr = useSelector((state) => state.hotelData.error);

  const [activeId, setActiveId] = useState();
  let val = "true";

  const navItems = [
    { id: 1, name: "About Look", path: "/about-look" },
    { id: 2, name: "Weather", path: "/Weather" },
    // { id: 3, name: "Search for Business" },
  ];

  // Get Page data useEffect

  useEffect(() => {
    setLoader(true);
    if (hotelData !== null) {
      setLoader(false);
      if (hotelData.data.code == 200) {
        setData(hotelData.data.data);

        const area = hotelData.data.data.area;
        const suburb = hotelData.data.data.suburb;
        const data = [area, suburb];
        const parseData = JSON.stringify(data);
        localStorage.setItem("HotelData", parseData);
        localStorage.removeItem("listingType");

        if (window.location.pathname.substring(1) == hotelData.data.data.key) {
          console.warn("true");
        }
      }
    } else if (hotelErr !== null) {
      setLoader(false);
    }

    return () => {
      setActiveId("");
    };
  }, [hotelData, hotelErr]);

  const handleData = () => {
    const hoteldata = JSON.parse(localStorage.getItem("HotelData"));
    console.warn(hoteldata[0]);
    localStorage.removeItem("listingType");
    let urlKet = localStorage.getItem("urlKey");
    navigate(`/${urlKet}`);
  };

  // const handleSearch = (e) => {
  //   if (e.target.value.length > 0) {
  //     setSearchData(e.target.value);
  //     dispatch(RequestSearch());
  //     navigate("/search-business");
  //     let data = {
  //       listingType: listing_type ? listing_type : 0,
  //       area: hoteldata[0],
  //       suburb: hoteldata[1],
  //       categoryId: 0,
  //       serviceId: 0,
  //       query: e.target.value,
  //     };

  //     dispatch(Search(data));
  //   } else {
  //     let urlKet = localStorage.getItem("urlKey");
  //     navigate(`/${urlKet}`);
  //     setmobileMenu(false);
  //   }
  // };

  // Search Bar Api

  const handleChangeSearch = (srchstring) => {
    console.log(srchstring, "srchstring--srchstring");
    setSearchString(srchstring);
    onChangeSearch(srchstring);
    if (srchstring.length > 2) {
      delayedQuery(
        listing_type ? listing_type : 0,
        hoteldata[0],
        hoteldata[1],
        0,
        0,
        srchstring
      );
    } else {
      let urlKet = localStorage.getItem("urlKey");
      console.log(urlKet, "urlKet--urlKet");
      navigate(`/${urlKet}`);
      setSearchResults("");
    }
  };

  const sendQuery = (
    listingType,
    area,
    suburb,
    categoryId,
    serviceId,
    query
  ) => {
    console.log(listingType, area, suburb, categoryId, serviceId, query);
    const url = `${apiActiveURL}/search_business?listing_type=${listingType}&area=${area}&subrub=${suburb}&category_id=${categoryId}&service_id=${serviceId}&q=${query}`;
    console.log(url);
    const options = {
      method: "GET",
      headers: {
        AppKey: appKey,
        AppId: appId,
      },
      url,
    };
    axios(options)
      .then((response) => {
        setLoader(true);
        console.log(response, "response");
        if (response?.data?.data?.length > 0) {
          setSearchResults(response.data.data);
          navigate("/search-business", {
            state: {
              state: response.data.data,
            },
          });
          setLoader(false);
          setmobileMenu(false);
        } else {
          console.log("search_business", response);
          setSearchResults([{ title: "No Data Found..." }]);
          navigate("/search-business", {
            state: {
              state: [{ title: "No Data Found..." }],
            },
          });
        }
      })
      .catch((error) => {
        setSearchResults([{ title: "No Data Found..." }]);
        console.log("search_business", error);
      });
  };

  const handleClick = (item) => {
    if (item.name == "Weather") {
      navigate("/Weather", {
        state: {
          state: hotelData?.data?.data?.area,
        },
      });
    } else if (item.name == "About Look") {
      navigate("/about-look");
    }
  };
  return (
    <>
      {hotelErr == null ? (
        <header>
          <Container>
            <div className="header_wrapper desktop_header_wrapper">
              <div
                className="header_nav_brand"
                style={{ cursor: "pointer" }}
                onClick={() => handleData()}
              >
                <img src={hotelData?.data?.data?.file} alt="" />
              </div>

              <div className="header_nav_link">
                {navItems?.map((item, index) => (
                  <a
                    role="button"
                    key={index}
                    onClick={() => handleClick(item)}
                    className={`header_nav_item_link ${
                      window?.location?.pathname === item?.path
                        ? "active_nav"
                        : ""
                    }`}
                  >
                    {item?.name}
                  </a>
                ))}
                <div className="search_field desktop_search_field">
                  <input
                    ref={Inputref}
                    className="search_button"
                    placeholder="Search for a business"
                    onChange={(e) => handleChangeSearch(e.target.value)}
                  />
                  <div className="search_loader">
                    {searchString?.length > 2 && searchResults?.length == 0 ? (
                      <>
                        <Spinner
                          animation="border"
                          variant="white"
                          style={{
                            display: "block",
                            margin: "0 auto",
                            height: "20px",
                            width: "20px",
                          }}
                        />
                      </>
                    ) : (
                      <IoIosSearch className="search_icon" width={"100px"} />
                    )}
                  </div>
                </div>
                {window.location.pathname.substring(1)?.toUpperCase() ==
                hotelData?.data?.data?.key ? null : (
                  <a
                    role="button"
                    className="nav_last_btn"
                    onClick={() => navigate(-1)}
                  >
                    Back
                    <div className="nav_last_btn_img">
                      <img src={BackArrow} alt="" />
                    </div>
                  </a>
                )}
              </div>
            </div>

            <div className="mobile_header_warpper d-lg-none">
              <div className="mobile_header_warpper_inner">
                <div
                  className="header_nav_brand"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleData()}
                >
                  <img src={hotelData?.data?.data?.file} alt="" />
                </div>
                <div className="menu_icon">
                  <button onClick={() => handleMobileMenu()}>
                    {mobileMenu ? (
                      <RxCross1 size={37} strokeWidth="0.2" />
                    ) : (
                      <RiMenu3Line size={37} />
                    )}
                  </button>
                </div>
              </div>
              {mobileMenu && (
                <div className="header_nav_link_main" ref={menuRef}>
                  <div className="header_nav_link_main_inner">
                    <div className="header_nav_link">
                      {navItems?.map((item, index) => (
                        <a
                          role="button"
                          key={index}
                          onClick={() => {
                            handleClick(item);
                            setmobileMenu(false);
                          }}
                          className={`header_nav_item_link ${
                            window?.location?.pathname === item?.path
                              ? "active_nav"
                              : ""
                          }`}
                        >
                          {item?.name}
                        </a>
                      ))}

                      <div className="search_field desktop_search_field">
                        <input
                          ref={Inputref}
                          className={`search_button ${
                            searchString?.length > 2 &&
                            searchResults?.length == 0
                              ? "search_button_loader"
                              : ""
                          }`}
                          placeholder="Search for a business"
                          onChange={(e) => handleChangeSearch(e.target.value)}
                        />
                        <div className="search_loader">
                          {searchString?.length > 2 &&
                          searchResults?.length == 0 ? (
                            <>
                              <Spinner
                                animation="border"
                                variant="white"
                                style={{
                                  display: "block",
                                  margin: "0 auto",
                                  height: "20px",
                                  width: "20px",
                                }}
                              />
                            </>
                          ) : (
                            <IoIosSearch
                              className="search_icon"
                              width={"100px"}
                            />
                          )}
                        </div>
                      </div>
                      {window.location.pathname.substring(1)?.toUpperCase() ==
                      hotelData?.data?.data?.key ? null : (
                        <a
                          role="button"
                          className="nav_last_btn"
                          onClick={() => {
                            navigate(-1);
                            setmobileMenu(false);
                          }}
                        >
                          Back
                          <BsArrowRight
                            size={20}
                            color="#60aa47"
                            strokeWidth="0.5"
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Container>
        </header>
      ) : null}
    </>
  );
};

export default Header;
