import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const GetBusiness = (servicedetailid) => {
  var config = {
    url: `${apiActiveURL}/service/${servicedetailid}`,
    method: "GET",
    headers: {
      AppKey: appKey,
      AppId: appId,
    },
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        if (res.data.status) {
          GetBusinessSuccess(dispatch, res);
        } else {
          GetBusinessFail(dispatch, res.data.message);
        }
      })
      .catch((e) => {
        GetBusinessFail(dispatch, e.message);
      });
  };
};

export const ResetGetBusiness = () => {
  return (dispatch) => {
    dispatch({ type: "GET_BUSINESS_SUCCESS", payload: null });
  };
};

export const ResetGetBusinessError = () => {
  return (dispatch) => {
    dispatch({ type: "GET_BUSINESS_FAIL", payload: null });
  };
};

const GetBusinessSuccess = (dispatch, res) => {
  dispatch({ type: "GET_BUSINESS_SUCCESS", payload: res });
};

const GetBusinessFail = (dispatch, res) => {
  dispatch({ type: "GET_BUSINESS_FAIL", payload: res });
};
