import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import ServicesList from "../Components/ServicesList/ServicesList";
import { useDispatch, useSelector } from "react-redux";
import { Service, ResetService, ResetServiceFail } from "../Redux/Actions";
import { BounceLoader, FadeLoader } from "react-spinners";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export const Services = () => {
  const dispatch = useDispatch();
  const servicePerPage = 6;
  const location = useLocation();
  const [serviceData, setServiceData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState("");
  const [next, setNext] = useState(servicePerPage);

  const listing_type = localStorage.getItem("listingType");
  const hoteldata = JSON.parse(localStorage.getItem("HotelData"));

  const ServiceSuccess = useSelector((state) => state?.Services);
  const ServiceFail = useSelector((state) => state?.Services?.error);
  useEffect(() => {
    const data = {
      catId: localStorage.getItem("serviceId"),
      listingType: listing_type,
      area: hoteldata[0],
      suburb: hoteldata[1],
    };
    if (data?.catId) {
      dispatch(Service(data));
    } else {
      console.log("no id");
    }

    return () => {
      dispatch(ResetService());
      dispatch(ResetServiceFail());
    };
  }, []);

  // const handleShowMorePosts = () => {
  //   setNext(next + servicePerPage);
  // };

  useEffect(() => {
    if (ServiceSuccess?.service?.data.code == 200) {
      setLoader(false);

      if (ServiceSuccess.service?.data?.data.length > 0) {
        setServiceData(
          ServiceSuccess?.service?.data?.data.sort((a, b) =>
            a.title.localeCompare(b.title)
          )
        );
        // dispatch(ResetCategory());
      } else {
        setError("No Service Found");
        setLoader(false);
      }
    } else if (ServiceFail) {
      //  toast.error(ServiceFail, {
      //    position: "top-center",
      //    autoClose: 3000,
      //    hideProgressBar: false,
      //    closeOnClick: true,
      //    pauseOnHover: true,
      //    draggable: true,
      //    progress: undefined,
      //    theme: "colored",
      //  });
      // dispatch(ResetServiceFail());
      // dispatch(ResetService())
      setLoader(false);
    }
  }, [ServiceSuccess, ServiceFail]);

  const ser = [
    { id: 1, img: "ds", title: "sd", text: "sd" },
    { id: 2, img: "ds", title: "sd", text: "sd" },
    { id: 3, img: "ds", title: "sd", text: "sd" },
    { id: 4, img: "ds", title: "sd", text: "sd" },
    { id: 5, img: "ds", title: "sd", text: "sd" },
  ];
  return (
    <div className="services_pg">
      <Container>
        <Row>
          <Col md={12}>
            <div className="heading">
              <h1>{location.state.state}</h1>
            </div>
          </Col>
        </Row>
        <Row>
          {loader ? (
            <>
              {/* <FadeLoader
                color={color}
                loading={loader}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              /> */}
              <Spinner
                animation="border"
                variant="dark"
                style={{
                  display: "block",
                  margin: "0 auto",
                  height: "56px",
                  width: "10px",
                }}
              />
            </>
          ) : serviceData.length > 0 ? (
            <>
              {/* {serviceData.slice(0, next).map((item, index) => ( */}
              {serviceData.map((item, index) => (
                <ServicesList key={index} data={item} />
              ))}
            </>
          ) : (
            <>
              <h2>No Services</h2>
            </>
          )}

          {/* {next < serviceData.length && (
            <>
              <div className="btn_div">
                <Button
                  className="black_btn"
                  // name="Load More"
                  style={{
                    backgroundColor: "#bae2aa",
                    borderColor: "#ffff",
                    width: "150px",
                    height: "50px",
                    color: "black",
                  }}
                  onClick={handleShowMorePosts}
                >
                  Load More
                </Button>
              </div>
            </>
          )} */}

          {/* {serviceData.map((item, index) => (
            <ServicesList key={index} data={item} />
          ))} */}
        </Row>
      </Container>
    </div>
  );
};
