import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const AboutLookAustralia = () => {
  const visitLook = [
    { id: 1, name: "Restaurants, bars, and other eateries" },
    { id: 2, name: "Things to do in the area" },
    { id: 3, name: "Tours, activities &amp; attractions" },
    { id: 4, name: "Events that might be happening" },
    { id: 5, name: "For children &amp; families" },
    { id: 6, name: "Special offers, promotions, and value" },
  ];
  return (
    <div className="about_look_aus">
      <Container>
        <Row>
          <Col md={8} className="offset-md-2">
            <h2>About Look</h2>
            <p>
              Look has been developed by TEAM Australia to help tourists and
              visitors to a particular town or tourism/visitor precinct to look
              about the local area and its history, and tap into everything they
              need to know to make the most of their visit:
            </p>
            <ul>
              {visitLook?.map((list) => (
                <li>{list?.name}</li>
              ))}
            </ul>
            <p>
              We hope you enjoy using Look. If you would like to learn more
              about Look or TEAM Australia, please send us an email -{" "}
              <a href="mailto:info@teamts.com.au">info@teamts.com.au</a> - or
              just give us a call -{" "}
              <a className="bottom_contact_info" href="tel:07 5635 4369">
                07 5635 4369
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutLookAustralia;
