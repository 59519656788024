import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const GetCategory = (data) => {
  const hoteldata = JSON.parse(localStorage.getItem("HotelData"));
  var config = {
    url: `${apiActiveURL}/categories?subrub=${hoteldata[1]}&listing_type=${data.listingType}`,
    method: "GET",
    headers: {
      AppKey: appKey,
      AppId: appId,
    },
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        if (res.data.status) {
          CategorySuccess(dispatch, res);
        } else {
          CategoryFail(dispatch, res.data.message);
        }
      })
      .catch((e) => CategoryFail(dispatch, e.message));
  };
};

export const ResetCategory = () => {
  return (dispatch) => {
    dispatch({ type: "CATEGORY_SUCCESS", payload: null });
  };
};

const CategorySuccess = (dispatch, res) => {
  dispatch({ type: "CATEGORY_SUCCESS", payload: res });
};

export const CategoryId = (res) => {
  return (dispatch) => {
    dispatch({ type: "CATEGORY_ID", payload: res });
  };
};
const CategoryFail = (dispatch, res) => {
  dispatch({ type: "CATEGORY_FAIL", payload: res });
};
export const ResetCategoryFail = () => {
  return (dispatch) => {
    dispatch({ type: "CATEGORY_FAIL", payload: null });
  };
};
