const INITIAL_STATE = {
  childcategory: null,
  service_id: 0,
  error: null,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CHILD_CATEGORY_SUCCESS":
      return { ...state, childcategory: action.payload };
    case "SERVICE_ID":
      return { ...state, service_id: action.payload };
    case "CHILD_CATEGORY_FAIL":
      return { ...state, error: action.payload };

    default:
      return state;
  }
};
