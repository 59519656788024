import React from "react";
import { Col } from "react-bootstrap";
import box_img from "../../Asstes/Images/box_img.png";
import { useNavigate } from "react-router-dom";
const ServicesList = ({ data }) => {
  const navigate = useNavigate();

  const handleBusinessData = () => {
    console.log(data);
    navigate("/business-detail", {
      state: data,
    });
  };
  return (
    <>
      {data?.title == "No Data Found..." ? (
        <Col lg={10} md={12} className="offset-lg-1 offset-md-0">
          <div className="search_record_not_found">
            <div className="record_not_found_inner">
              <h2>No Data Found</h2>
            </div>
          </div>
        </Col>
      ) : (
        <Col lg={4} md={6}>
          <div
            className="ServicesList tile_box"
            onClick={() => handleBusinessData()}
          >
            <div
              className="img"
              style={{
                backgroundImage: `url(${data.image})`,
              }}
            ></div>
            <div className="title">
              <h3>{data.title}</h3>
            </div>
            <div className="text">
              <p
                dangerouslySetInnerHTML={{
                  __html: data.short_description,
                }}
              >
                {/* {text} */}
              </p>
            </div>
          </div>
        </Col>
      )}
    </>
  );
};

export default ServicesList;
