import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import ThemeReducer from "./ThemeReducer";
// import GuestDirectory from "./GuestDirectory";
// import Hotel from "./Hotel";
// import GetData from "./GetData";
import GetHotelData from "./GetHotelData";
import Category from "./Category";
import ChildCategory from "./ChildCategory";
import Services from "./Services";
import Search from "./Search";
import GetBusiness from "./GetBusiness";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  //   mode: persistReducer(persistConfig, ThemeReducer),
  //   guest: GuestDirectory,
  //   hotel: Hotel,
  // hotel: persistReducer(persistConfig, Hotel),
  hotelData: GetHotelData,
  Category: persistReducer(persistConfig, Category),
  ChildCategory: persistReducer(persistConfig, ChildCategory),
  Services: persistReducer(persistConfig, Services),
  Search: Search,
  GetBusiness: GetBusiness,
});

const appReducer = (state, action) => {
  if (action.type == "LOGOUT_USER_SUCCESS") {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};

export const store = createStore(appReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
