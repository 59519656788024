import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Category } from "../Pages/Category";
import { Home } from "../Pages/Home";
import { Services } from "../Pages/Services";
import ChildCategories from "../Pages/ChildCategory";
import { SearchBusiness } from "../Pages/SearchBusiness";
import Weather from "../Pages/Weather";
import AboutLookAustralia from "../Pages/AboutLook";
import TeamAustralia from "../Pages/TeamAustralia";
import BusinessDetail from "../Components/BusinessDetail/BusinessDetail";

const AppRouts = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="/:id" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/business-detail" element={<BusinessDetail />} />
        <Route path="/category" element={<Category />} />
        <Route path="/child-category" element={<ChildCategories />} />
        <Route path="/search-business" element={<SearchBusiness />} />
        <Route path="/Weather" element={<Weather />} />
        <Route path="/about-look" element={<AboutLookAustralia />} />
        <Route path="/team-australia" element={<TeamAustralia />} />
      </Routes>
    </React.Fragment>
  );
};
export default AppRouts;
