import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import cc_img from "../Asstes/Images/icon_1.png";
import { useLocation } from "react-router-dom";
import { appId, appKey, apiActiveURL } from "../ApiBaseURL";
import axios from "axios";
import { Spinner } from "react-bootstrap";

const Weather = () => {
  const [weather, setWeather] = useState(null);
  const [forcast, setForcast] = useState([]);
  const [loader, setLoader] = useState(false);
  const location = useLocation();

  const regions = {
    Sydney: "CITY_SYDNEY",
    Melbourne: "CITY_MELBOURNE",
    Brisbane: "CITY_BRISBANE",
    Canberra: "CITY_CANBERRA",
    Darwin: "CITY_DARWIN",
    Hobart: "CITY_HOBART",
    "Gold Coast": "CITY_GOLD_COAST",
    Perth: "CITY_PERTH",
    Adelaide: "CITY_ADELAIDE",
    Perth: "CITY_BYRON_BAY",
    "Hunter Region": "CITY_HUNTER_REGION",
    "Southern Highlands": "CITY_SOUTHERN_HIGHLANDS",
    "Central Coast": "CITY_CENTRAL_COAST",
    "Maclaren Vale": "CITY_MACLAREN_VALE",
    "Tweed Heads": "CITY_TWEED_HEADS",
    "Sunshine Coast": "CITY_SUNSHINE_COAST",
  };

  useEffect(() => {
    // Promise.all(fetchWeather());
    setLoader(true);
    fetchWeather();
  }, []);

  const fetchWeather = async () => {
    setLoader(true);
    let region = "";

    region = location.state.state;

    region = regions[region];
    if (!region) {
      return null;
    } else {
      setLoader(true);
      const url = `${apiActiveURL}/get_weather/${region}`;
      const options = {
        method: "GET",
        headers: {
          AppKey: appKey,
          AppId: appId,
        },
        url,
      };
      await axios(options)
        .then((res) => {
          if (res.data.code === 200) {
            setWeather(res?.data?.data?.channel?.item);

            setForcast(res?.data?.data?.channel?.item[1]["w:forecast"]);
            setLoader(false);
          }
        })
        .catch((error) => {
          console.log("weather", error);
        });
    }
  };
  return (
    <div className="Weather_pg">
      {weather ? (
        <>
          <Container>
            <Row>
              <Col lg={6} md={8} className="offset-lg-3 offset-md-2">
                <div className="category_box weather_green_box">
                  <Row>
                    <Col md={10}>
                      <p>Current Conditions:</p>
                      <h4>
                        {weather && weather[0]["w:current"]["@temperature"]}°C
                      </h4>
                      <p>
                        <strong>Dew Point: </strong>{" "}
                        {weather[0]["w:current"]["@dewPoint"]} Relative Humidity{" "}
                        {weather[0]["w:current"]["@humidity"]}% Wind Speed:
                        {weather[0]["w:current"]["@windSpeed"]}
                        km/h, Wind Gust: {weather[0]["w:current"]["@windGusts"]}
                        , Wind Direction:{" "}
                        {weather[0]["w:current"]["@windDirection"]} Pressure:
                        {weather[0]["w:current"]["@pressure"]} hPa. Rain Since 9
                        am: {weather[0]["w:current"]["@rain"]}mm
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              {forcast.map((item, index) => (
                <>
                  <Col lg={4} md={6} key={index}>
                    <div className="category_box weather_box">
                      <div className="cat_title">
                        <h4>{item["@day"]}</h4>
                        <p>
                          {item["@description"]}
                          <sapn>
                            {item["@min"]} - {item["@max"]} ℃
                          </sapn>
                        </p>
                      </div>
                    </div>
                  </Col>
                </>
              ))}
            </Row>
          </Container>
        </>
      ) : (
        <>
          <Spinner animation="border" variant="dark" />
        </>
      )}
    </div>
  );
};

export default Weather;
