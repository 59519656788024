import "./App.css";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import AppRouts from "./Routes/Routs";
import DownloadLinkStore from "./Components/DownloadLink/DownloadLink";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";

const App = () => {
  const hotelErr = useSelector((state) => state.hotelData.error);
  const Inputref = useRef(null);
  const [mobileMenu, setmobileMenu] = useState(false);

  const handleRef = () => {
    Inputref.current.focus();
  };

  return (
    <div
      className={
        hotelErr || window.location.pathname == "/" ? "App App_Error" : "App"
      }
    >
      {window.location.pathname == "/" ? null : (
        <Header
          Inputref={Inputref}
          mobileMenu={mobileMenu}
          setmobileMenu={setmobileMenu}
        />
      )}

      <div className="middle_body">
        <AppRouts />
        <DownloadLinkStore />
      </div>
      {window.location.pathname == "/" ? null : (
        <Footer
          handleRef={handleRef}
          mobileMenu={mobileMenu}
          setmobileMenu={setmobileMenu}
        />
      )}
    </div>
  );
};

export default App;
