import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";
import footer_logo from "../Asstes/Images/footer_logo.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
const Footer = ({ handleRef, setmobileMenu, mobileMenu }) => {
  const navigate = useNavigate();
  const [showTopBtn, setShowTopBtn] = useState(false);
  const hotelErr = useSelector((state) => state.hotelData.error);

  const isMobileSize = useMediaQuery({ query: "(max-width: 768px)" });

  const hotelData = useSelector((state) => {
    return state.hotelData.data;
  });

  const handleClick = () => {
    navigate("/Weather", {
      state: {
        state: hotelData?.data?.data?.area,
      },
    });
  };

  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleActiveSearch = () => {
    if (isMobileSize) {
      if (mobileMenu) {
        handleRef();
      }
      setmobileMenu(true);
      handleScroll();
    } else {
      handleRef();
    }
  };

  return (
    <>
      {hotelErr == null ? (
        <>
          {" "}
          <div className="footer">
            {/* <Container>
              <Row>
                <Col
                  xl={2}
                  lg={4}
                  md={4}
                  className="offset-lg-5 offset-lg-4 offset-md-4"
                >
                  <div className="Footer_logo">
                    <a href="#">
                      <img src={hotelData?.data?.data?.file} />
                    </a>
                  </div>
                </Col>
              </Row>
            </Container> */}
            <div className="footer_menu">
              <Container>
                <Row>
                  <Col md={12}>
                    <ul>
                      <li
                        onClick={() => {
                          handleScroll();
                          navigate("/about-look");
                        }}
                      >
                        About Look
                      </li>
                      <li
                        onClick={() => {
                          handleScroll();
                          handleClick();
                        }}
                      >
                        Weather
                      </li>
                      <li className="footer_list">
                        <a
                          id="focus_search_bar"
                          onClick={() => {
                            handleActiveSearch();
                          }}
                        >
                          Search for a Business
                        </a>
                      </li>
                      <li className="footer_list">
                        <a
                          target="_blank"
                          href="http://teamts.com.au/about-us/privacy-policy/"
                        >
                          Privacy Policy
                        </a>
                      </li>
                      <li
                        onClick={() => {
                          handleScroll();
                          navigate("/team-australia");
                        }}
                      >
                        Team Australia
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="copyrights">
              <Container>
                <Row>
                  <Col md={12}>
                    <p>Copyright 2023 All Rights Resereved.</p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Footer;
