import axios from "axios";
import { apiActiveURL, appId, appKey } from "../../ApiBaseURL";

export const ChildCategory = (data) => {
  const hoteldata = JSON.parse(localStorage.getItem("HotelData"));

  var config = {
    url: `${apiActiveURL}/categories/${data.catId}?subrub=${hoteldata[1]}&listing_type=${data?.listingType}`,
    method: "GET",
    headers: {
      AppKey: appKey,
      AppId: appId,
    },
  };

  return (dispatch) => {
    axios(config)
      .then((res) => {
        if (res.data.status) {
          ChildCategorySuccess(dispatch, res);
        } else {
          ChildCategoryFail(dispatch, res.data.message);
        }
      })
      .catch((e) => ChildCategoryFail(dispatch, e.message));
  };
};

export const ResetChildCategory = () => {
  return (dispatch) => {
    dispatch({ type: "CHILD_CATEGORY_SUCCESS", payload: null });
  };
};
export const ServiceId = (res) => {
  return (dispatch) => {
    dispatch({ type: "SERVICE_ID", payload: res });
  };
};

const ChildCategorySuccess = (dispatch, res) => {
  dispatch({ type: "CHILD_CATEGORY_SUCCESS", payload: res });
};

const ChildCategoryFail = (dispatch, res) => {
  dispatch({ type: "CHILD_CATEGORY_FAIL", payload: res });
};

export const ResetChildCategoryFail = () => {
  return (dispatch) => {
    dispatch({ type: "CHILD_CATEGORY_FAIL", payload: null });
  };
};
