import React, { useEffect, useState } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import ServicesList from "../Components/ServicesList/ServicesList";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export const SearchBusiness = () => {
  const location = useLocation();
  const servicePerPage = 6;
  const [searchData, setSearchData] = useState(null);
  const [searchResponse, setSearchResponse] = useState("");
  const [loader, setLoader] = useState(false);
  const [next, setNext] = useState(servicePerPage);

  const response = location?.state?.state;
  console.log(searchResponse, "searchResponse--searchResponse--searchResponse");

  useEffect(() => {
    setLoader(true);
    if (response?.length > 0) {
      setSearchResponse(response);
      setLoader(false);
    }
  }, []);

  const SearchSuccess = useSelector((state) => {
    return state?.Search?.data;
  });

  const SearchLoading = useSelector((state) => {
    return state?.Search?.loading;
  });

  const handleShowMorePosts = () => {
    setNext(next + servicePerPage);
  };

  // useEffect(() => {
  //   setLoader(!loader);
  //   if (SearchSuccess?.data?.code == 200) {
  //     setLoader(false);
  //     var arr = Object.values(SearchSuccess.data.data);
  //     setSearchData(arr);
  //   }
  //   return () => {
  //     //   dispatch(ResetSearch());
  //     //   dispatch(ResetSearchError());
  //     //   dispatch(ResetSeasrchError());
  //   };
  // }, [SearchSuccess, SearchLoading]);

  return (
    <div className="services_pg">
      <Container>
        <Row>
          <Col md={12}>
            <div className="heading">
              <h1>Casual Dining</h1>
            </div>
          </Col>
        </Row>
        <Row>
          {/* {SearchLoading ? (
            <>
              <Spinner
                animation="border"
                variant="dark"
                style={{
                  display: "block",
                  margin: "0 auto",
                  height: "56px",
                  width: "10px",
                }}
              />
            </>
          ) : null} */}

          {/* {!loader ? (
            <>
              <FadeLoader
                color={color}
                loading={loader}
                cssOverride={override}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </>
          ) : null} */}

          {loader ? (
            <>
              <Spinner
                animation="border"
                variant="dark"
                style={{
                  display: "block",
                  margin: "0 auto",
                  height: "56px",
                  width: "10px",
                }}
              />
            </>
          ) : searchResponse?.length > 0 ? (
            <>
              {searchResponse?.slice(0, next).map((item, index) => (
                <ServicesList key={index} data={item} />
              ))}
            </>
          ) : (
            <>
              <h2>No Services</h2>
            </>
          )}

          {/* {searchResponse?.length > 0 ? (
            <>
              {searchResponse?.slice(0, next).map((item, index) => (
                <ServicesList key={index} data={item} />
              ))}
            </>
          ) : (
            <>
              <h2>No Services</h2>
            </>
          )} */}

          {next < searchResponse?.length && (
            <>
              {loader ? null : (
                <>
                  <div className="btn_div">
                    <Button
                      className="black_btn"
                      // name="Load More"
                      style={{
                        backgroundColor: "#bae2aa",
                        borderColor: "#ffff",
                        width: "150px",
                        height: "50px",
                        color: "black",
                      }}
                      onClick={handleShowMorePosts}
                    >
                      Load More
                    </Button>
                  </div>
                </>
              )}
            </>
          )}

          {/* {serviceData.map((item, index) => (
            <ServicesList key={index} data={item} />
          ))} */}
        </Row>
      </Container>
    </div>
  );
};
