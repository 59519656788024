import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { FaChevronRight } from "react-icons/fa";
import icon_1 from "../../Asstes/Images/icon_1.png";
import { useNavigate } from "react-router-dom";
import { CategoryId } from "../../Redux/Actions";
import { useDispatch } from "react-redux";

const CategoryBox = ({ data, listingType }) => {
  console.log(data, "data--category--listing-type--data");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [emburl, setEmbUrl] = useState("");
  const handleRoute = () => {
    if (window.location.pathname == "/child-category") {
      console.warn(data.id, "if child category page");
      localStorage.setItem("serviceId", data.id);
      navigate("/services", {
        state: {
          state: data.name,
        },
      });
    } else if (data.id == "All") {
      console.warn(data.id, "go to services");
      localStorage.setItem("serviceId", data.id);
      navigate("/services", {
        state: {
          state: data.name,
        },
      });
    } else {
      console.warn(data.id, "category se category se");

      localStorage.setItem("categoryId", data.id);

      navigate("/child-category", {
        state: {
          state: data.name,
        },
      });
    }
  };

  return (
    <Col lg={4} md={6}>
      {data?.embed_url ? (
        <a href={data?.embed_url} target="_blank" style={{ color: "initial" }}>
          <div
            className="category_box multipule_category_box"
            // onClick={() => {
            //   dispatch(CategoryId(data.id));
            //   handleRoute();
            // }}
          >
            <div className="cat_icon">
              <img src={icon_1} />
            </div>
            <div className="cat_title">
              <h4>{data?.name}</h4>
            </div>
            <div className="link_btn">
              <a hrf="#">
                <FaChevronRight />
              </a>
            </div>
          </div>
        </a>
      ) : (
        <div
          className="category_box multipule_category_box"
          onClick={() => {
            dispatch(CategoryId(data.id));
            handleRoute();
          }}
        >
          <div className="cat_icon">
            <img src={icon_1} />
          </div>
          <div className="cat_title">
            <h4>{data?.name}</h4>
          </div>
          <div className="link_btn">
            <a hrf="#">
              <FaChevronRight />
            </a>
          </div>
        </div>
      )}
    </Col>
  );
};

export default CategoryBox;
