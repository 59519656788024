import React, { useEffect, useState } from "react";
import { CSSProperties } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CategoryBox from "../Components/CategoryBox/CategoryBox";
import {
  ChildCategory,
  ResetChildCategory,
  ResetChildCategoryFail,
} from "../Redux/Actions";
import { BounceLoader, FadeLoader } from "react-spinners";
import { Spinner } from "react-bootstrap";

const ChildCategories = () => {
  const listing_type = localStorage.getItem("listingType");

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [categoryData, setCategoryData] = useState([]);
  const [loader, setLoader] = useState(true);
  const servicePerPage = 6;
  const [next, setNext] = useState(servicePerPage);

  const hotelData = useSelector((state) => {
    return state.hotelData.data;
  });
  const ChildCategorySuccess = useSelector((state) => {
    return state?.ChildCategory;
  });
  const ChildCategoryFail = useSelector(
    (state) => state?.ChildCategories?.error
  );

  const CategoryId = useSelector((state) => state?.Category?.category_id);

  useEffect(() => {
    return () => {
      dispatch(ResetChildCategory());
      dispatch(ResetChildCategoryFail());
    };
  }, [listing_type]);
  useEffect(() => {
    setLoader(true);

    const data = {
      catId: localStorage.getItem("categoryId"),
      listingType: listing_type,
      area: hotelData?.data?.data?.area,
      suburb: hotelData?.data?.data?.suburb,
    };
    if (data?.catId) {
      dispatch(ChildCategory(data));
    } else {
      console.log("no id");
    }
  }, [CategoryId]);
  useEffect(() => {
    if (ChildCategorySuccess?.childcategory?.data.code == 200) {
      setLoader(false);
      if (ChildCategorySuccess.childcategory?.data?.data.length > 0) {
        setCategoryData(ChildCategorySuccess.childcategory?.data?.data);
      } else {
      }
    } else if (ChildCategoryFail) {
      setLoader(false);
    }
  }, [ChildCategorySuccess, ChildCategoryFail]);

  // const handleShowMorePosts = () => {
  //   setNext(next + servicePerPage);
  // };

  return (
    <div className="subcategory_pg">
      <Container>
        <Row>
          <Col md={12}>
            <div className="heading">
              {location.state.state ? (
                <>
                  <h1>{location.state.state}</h1>
                </>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row>
          {loader ? (
            <>
              <Spinner
                animation="border"
                variant="dark"
                style={{
                  display: "block",
                  margin: "0 auto",
                  height: "56px",
                  width: "10px",
                }}
              />
            </>
          ) : categoryData.length > 0 ? (
            <>
              {/* {categoryData.slice(0, next).map((val, index) => ( */}
              {categoryData.map((val, index) => (
                <CategoryBox data={val} />
              ))}
            </>
          ) : (
            <>
              <h2>No Categories</h2>
            </>
          )}

          {/* {next < categoryData.length && (
            <>
              <div className="btn_div">
                <Button
                  className="black_btn"
                  style={{
                    backgroundColor: "#bae2aa",
                    borderColor: "#ffff",
                    width: "150px",
                    height: "50px",
                    color: "black",
                  }}
                  onClick={handleShowMorePosts}
                >
                  Load More
                </Button>
              </div>
            </>
          )} */}
        </Row>
      </Container>
    </div>
  );
};

export default ChildCategories;
